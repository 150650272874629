import { Buffer } from "buffer";
const crypto = window.crypto;
const pwdKey =
  process.env.REACT_APP_RESP_ENCRYPTION_KEY ||
  "dF7d8LwkCxnkKxb2nUu1I/mH5OSDzPZet3SqKCWUdfd3";
const iv = new Uint8Array([
  99, 81, 61, 79, 92, 11, 32, 19, 38, 82, 20, 89, 79, 19, 59, 88,
]);

export const getCurrentMonth = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const date = new Date();

  const result = months[date.getMonth()];
  return result;
};

export const ErrorMsg = (error) => {
  const errorRes = error?.response?.data?.error?.message
    ? error?.response?.data?.error?.message
    : error?.message;
  return errorRes || "Something Went Wrong";
};

async function generateKey(exportedKeyCustom) {
  let importedKey;
  await crypto.subtle
    .importKey("raw", exportedKeyCustom, "AES-CBC", true, [
      "encrypt",
      "decrypt",
    ])
    .then((res) => (importedKey = res))
    .catch((error) => console.log(error));

  return importedKey;
}

async function getCryptoKey() {
  const exportedKeyCustom = Buffer.from(pwdKey, "base64");
  const result = await generateKey(exportedKeyCustom);
  return result;
}

export async function encryption(data) {
  const key = await getCryptoKey();
  if (data && key) {
    let formatData = JSON.stringify(data);
    let result;
    let enc = new TextEncoder();
    let encodedData = enc.encode(formatData);

    await crypto.subtle
      .encrypt(
        {
          name: "AES-CBC",
          iv,
        },
        key,
        encodedData
      )
      .then((res) => (result = Buffer.from(res).toString("base64")))
      .catch((error) => console.log(error));

    return result;
  }
}

export async function decryption(data) {
  const key = await getCryptoKey();
  if (data && key) {
    let result;
    await crypto.subtle
      .decrypt(
        {
          name: "AES-CBC",
          iv: iv, //The initialization vector you used to encrypt
        },
        key, //from generateKey or importKey above
        Buffer.from(data, "base64") //ArrayBuffer of the data
      )
      .then((res) => (result = res))
      .catch((error) => console.log(error));

    try {
      const decoder = new TextDecoder();
      if (result) return JSON.parse(decoder.decode(result));
    } catch (error) {
      console.log(error);
    }
  }
}
