import { useState } from "react";
import ReactSelect from "../../../components/third-party/ReactSelect";
import Box from "@mui/material/Box";
import { selectedYearValue } from "../../../store/reducers/common";
import { useDispatch } from "react-redux";

const YearSelect = () => {
  const [selectedValue, setSelectedValue] = useState(new Date().getFullYear());
  const [dropdownYearOptions, setDropdownYearOptions] = useState([
    { value: new Date().getFullYear(), label: new Date().getFullYear() },
  ]);
  const dispatch = useDispatch();

  const handleSelect = (option) => {
    dispatch(selectedYearValue({ selectedYear: option?.value }));
    setSelectedValue(option?.value);
  };

  return (
    <Box>
      <ReactSelect
        name={"year"}
        options={dropdownYearOptions}
        handleChange={(selectedOptions, e) => {
          handleSelect(selectedOptions);
        }}
        value={selectedValue}
        id="country-test"
        label="Year"
        labelPosition="left"
        width={140}
        defaultValue="ALL"
      />
    </Box>
  );
};
export default YearSelect;
