// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import loginReducer from '../../features/login/loginSlice'
import common from "./common";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu,loginReducer,common});

/**
 * Function is used to reset store data
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
const rootReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
       state = {};
    }
    return reducers(state, action);
   };
export default rootReducer;
