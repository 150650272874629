import Box from "@mui/material/Box";
import Select from "react-select";

const flexStyle={display:"flex",alignItems:"center",gap:2}
const customStyles = {
  control: (provided, { selectProps: { width } }) => ({
    ...provided,
    width: width,
    // border: "1px solid #1f445a",
    
    // // This line disable the blue border
    // boxShadow: "none",
    // outline: "none",
    // "&:hover": {
    //   outline: "none",
    // },
  }),

  // indicatorSeparator: (provided, state) => ({
  //   ...provided,
  //   display: "none",
  // }),
  // dropdownIndicator: (provided, state) => ({
  //   ...provided,
  //   padding: 0,
  // }),
};
const ReactSelect = (props) => {
  const {
    label,
    options,
    handleChange,
    handleInputChange,
    value,
    name,
    isDisabled,
    width,
    placeHolder,
    getOptionLabel,
    className,
    maxMenuHeight,
    labelPosition,
  } = props;
   let labelStyle=labelPosition ? flexStyle :{}
   const defaultOptions = [{ value: "ALL", label: "ALL" }];
  return (
    <Box sx={labelStyle}>
      <label>{label}</label>
      <Select
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        value={
          options?.length > 0 &&
          options.filter(function (option) {
            return option.value === value;
          })
        }
        placeholder={placeHolder ? placeHolder : label}
        isClearable={false}
        isSearchable
        name={name}
        defaultValue={defaultOptions && defaultOptions[0]}
        isDisabled={isDisabled}
        noOptionsMessage={() => "No records found!"}
        //   components={{ DropdownIndicator }}
        styles={customStyles}
        width={width}
        getOptionLabel={getOptionLabel}
        className={className}
        maxMenuHeight={maxMenuHeight && maxMenuHeight}
      />
    </Box>
  );
};
export default ReactSelect;
