import {StrictMode} from "react";
import ReactDOM from "react-dom/client";
import AppRoutes from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";
import ThemeCustomization from "./themes";

// css
import "./assets/scss/common.scss"

// const theme = createTheme();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeCustomization>
        <AppRoutes />
      </ThemeCustomization>
    </Provider>
  </StrictMode>
);
