// assets
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import GridViewIcon from '@mui/icons-material/GridView';

// ==============================|| MENU ITEMS ||============================== //

// icons
const icons = {
  DashboardIcon,
  LogoutIcon,
  PublicOutlinedIcon,
  GridViewIcon
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "group-dashboard",
  title: "Navigation",
  type: "group",
  children: [
    {
      id: "Dashboard",
      title: "App Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icons.DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: "Map View",
      title: "Trial Location",
      type: "item",
      url: "/trial-location",
      icon: icons.PublicOutlinedIcon,
      breadcrumbs: false,
    },
    {
      id: "targetdashboard",
      title: "Target Dashboard",
      type: "item",
      url: "/target-dashboard",
      icon: icons.GridViewIcon,
      breadcrumbs: false,
    },
  ],
};

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
  id: "authentication",
  // title: 'Authentication',
  type: "group",
  children: [
    {
      id: "logout",
      title: "Logout",
      type: "item",
      url: "/login",
      icon: icons.LogoutIcon,
      target: false,
    },
  ],
};
const menuItems = {
  items: [dashboard, pages],
};

export default menuItems;
