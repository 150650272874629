// Configuration of API URL
export const apiURL = {
  login: "login/loginweb",
  azureToken:"login/getAzureToken",
  analytics: {
    getMonth: "analytics/getMonthWiseAnalytics",
    getMVM: "analytics/getMostVisitedModules",
    getCurrentByMonthUser: "analytics/userStats",
    getVisitByMonth: "analytics/getVisitorByMonth",
    getCountry: "analytics/getCountries",
  },
  trials: {
    getLocation: "activities/getTrailsWebMapView",
    getAssessment: "activities/getAssessmentsByTraialId",
  },
  targetDashboard: {
    getTrialLeaderBoard: "analytics/trialLeaderBoard",
    getUserLeaderBoard: "analytics/userLeaderBoard",
    getUserTrialLeaderBoard: "analytics/userTrialLeaderBoard",
  },
  otp: {
    sendOtp: "login/sendCode",
    verifyOtp: "login/verifyCode",
  },
};
