// material-ui
import { Box, IconButton, Link, useMediaQuery } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";

// project import
import Search from "./Search";
import Profile from "./Profile";
import Notification from "./Notification";
import MobileSection from "./MobileSection";
import CountrySelect from "./CountrySelect";

import { useLocation } from "react-router-dom";
import YearSelect from "./YearSelect";
import { Fragment } from "react";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { pathname } = useLocation();
  return (
    <Box display="flex" alignItems="center" gap={1}>
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      {/* {!matchesXs && <Search />}
      <IconButton
        component={Link}
        // href=""
        // target="_blank"
        disableRipple
        color="secondary"
        title="refresh"
        sx={{ color: "text.primary", bgcolor: "grey.100" }}
      >
        <AutorenewIcon />
      </IconButton>

      <Notification /> */}
      {pathname === "/target-dashboard" ? (
        <Fragment>
          <CountrySelect />
          <YearSelect />
        </Fragment>
      ) : (
        <CountrySelect />
      )}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </Box>
  );
};

export default HeaderContent;
