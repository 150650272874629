// Routes.js
import { lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import Layout from "../layouts";
import PublicRoute from "./PublicRoutes";
import Toastr from "../components/ui/Toastr";
import Loader from "../components/ui/Loader";

const Dashboard = lazy(() => import("../pages/Dashboard"));
const Login = lazy(() => import("../pages/Login"));
const CustomMap = lazy(()=>import("../pages/Trial"))
const Analytics = lazy(() => import("../pages/Analytics"));

const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
         <Toastr />
        <Routes>
          <Route element={<Layout />}>
          {/* Private Routes */}
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
             <Route
              path="/trial-location"
              element={
                <PrivateRoute>
                  <CustomMap />
                </PrivateRoute>
              }
            />
             <Route
              path="/target-dashboard"
              element={
                <PrivateRoute>
                  <Analytics />
                </PrivateRoute>
              }
            />
          </Route>
          {/* Public Routes */}
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
         
           {/* URL is not applicable after redirect dashboard page */}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
