 import logo from '../../assets/images/bayagri_logo.png';
//  import logo1   from '../../assets/images/bayagri_logo_no_bg.png';
// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return <img src={logo} alt="Logo" width={55} />
    
};

export default Logo;
