// types
import { createSlice } from "@reduxjs/toolkit";

// initial state
const initialState = {
  countryCode: "ALL",
  selectedYear: new Date().getFullYear(),
};

// ==============================|| SLICE - Common  ||============================== //

const commonRedux = createSlice({
  name: "commonRedux",
  initialState,
  reducers: {
    activeCountryCode(state, action) {
      state.countryCode = action.payload.countryCode;
    },
    selectedYearValue(state,action){
      state.selectedYear = action.payload.selectedYear;
    }
  },
});

export default commonRedux.reducer;

export const { activeCountryCode ,selectedYearValue} = commonRedux.actions;
