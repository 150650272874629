import LocalStorage from './localStorage';
class Authorization {
  constructor() {
    this.authUser = null;
    this.authUserKey = 'auth_user';
  }

  /**
   * set auth user details to class property
   */
  setAuthUser() {
    this.authUser = LocalStorage.getLocalStorageValue(this.authUserKey);
  }

  /**
   * check is active user is logged in
   */
  isLoggedIn() {
    const ls = LocalStorage.getLocalStorageValue(this.authUserKey);
    return ls && ls?.userrole ? true : false;
    // return true;
  }
  /**
   * get logged in user details
   */
  getAuthUser() {
    if (this.isLoggedIn() && !this.authUser) {
      this.setAuthUser();
    }
    return this.authUser;
  }

  // /**
  //  * Get authentication access token
  //  */
  // getAccessToken() {
  //   const authUser = this.getAuthUser();
  //   return authUser && typeof authUser?.token === 'string'
  //     ? authUser?.token
  //     : null;
  // }
   /**
   * Get authentication access token
   *
   * @return string
   */
    getAccessToken() {
      const accessToken=  LocalStorage.getLocalStorageValue("accessToken");
      return accessToken ? accessToken : null;
    }

  /**
   * Get authentication refresh token
   */
  getRefreshToken() {
    const authUser = this.getAuthUser();
    return authUser && typeof authUser.refresh_token === 'string'
      ? authUser.refresh_token
      : null;
  }

  /**
   * login the user by setting it in local storage
   * @param {object} data
   */
  login(data) {
    if (typeof Storage !== 'undefined') {
      LocalStorage.removeLocalStorageValue(this.authUserKey);
      LocalStorage.setLocalStorageValue(this.authUserKey, data);
    } else {
      console.error('local storage is not supported');
    }
  }

  /**
   * get logged in user details
   */
  logout(isReload) {
    //clear URL stored in localStorage
    if (typeof Storage !== 'undefined') {
      LocalStorage.removeLocalStorageValue(this.authUserKey);
      LocalStorage.removeLocalStorageValue("accessToken");
      this.setAuthUser();
      this.authUser = null;
      isReload && window.location.reload();
    } else {
      console.error('local storage is not supported');
    }
  }
}

export default new Authorization();
