import React from "react";
import {Navigate } from "react-router-dom";
import Authorization from '../utils/authorization';


export default function PrivateRoute({ children }) {
  if (!Authorization.isLoggedIn()) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" />;
  }
  // authorized so return child components
  return children;
}
